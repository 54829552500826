<template>
  <div>
  <TosListVuetify serviceName="registry" entityName="goods-types" :headers="headers" >
    <template #customFilters="slotProps">
      <v-container fluid>
       <v-row>         
          <v-col cols="3">
            <v-select :label="tLabel('Goods sys. subtype')"  v-model="slotProps.data.sysSubtype" :items="sysSubtypes" />
          </v-col>
          <v-col cols="3">           
            <v-text-field  :label="tLabel('Goods type code')" v-model="slotProps.data.goodsTypeCode" />
          </v-col>
       </v-row>
      </v-container>
    </template>
    <template v-slot:editF="slotProps">
        <v-container fluid>
          <v-row>           
            <v-col>
              <v-select :label="tLabel('Goods sys. subtype')"  v-model="slotProps.data.sysSubtype" :items="sysSubtypes" :rules="[rules.required]" :error-messages="slotProps.errors.sysSubtype" />
            </v-col>
            <v-col>
              <v-text-field :label="tLabel('Goods type code')"  v-model="slotProps.data.goodsTypeCode" :rules="[rules.required]" :error-messages="slotProps.errors.goodsTypeCode" />
            </v-col>
            <v-col>
              <v-text-field :label="tLabel('HS code')"  v-model="slotProps.data.hsCode" :error-messages="slotProps.errors.hsCode" />
            </v-col> 
            <v-col>
              <v-select :label="tLabel('Count Type')" v-model="slotProps.data.countType" clearable :items="countTypes" :error-messages="slotProps.errors.countType" />
            </v-col>            
          </v-row>
       </v-container>
    </template>
  </TosListVuetify>

</div>

</template>


<script>
import TosListVuetify from '@/components/anagrafiche/TosListVuetify';
import FormatsMixins from "@/mixins/FormatsMixins";

export default {
  name: "GoodsTypes",
  data() {
    return {
      sysSubtypes: [],
      countTypes: [],
      headers: [
        { text: "", value: "action" },
        { text: `${this.tLabel('Goods sys. subtype')}`, value: "sysSubtype" },
        { text: `${this.tLabel('Goods type code')}`, value: "goodsTypeCode" },
        { text: `${this.tLabel('HS code')}`, value: "hsCode" },
        { text: `${this.tLabel('Count Type')}`, value: "countType" },
      ],
    };
  },
  mounted(){
    this.loadCombo();
  },
  
  methods: {
    async prepareItem(data) {
    },
    async loadCombo() {
      this.sysSubtypes = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "lookups/goodsTypes");
      this.countTypes = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "lookups/countTypes");
    }    
  },
  components: {TosListVuetify},
  mixins: [FormatsMixins],
};
</script>
